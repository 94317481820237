import { Col, Row } from "react-bootstrap";
import FormControl from "../../Components/FormControl";

const Form1 = ({ formik }) => {
  console.log("formik", formik.values);

  return (
    <Row>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="fullName"
          border="1px solid #EFEFF4"
          placeholder="Full Name"
          className={
            formik.errors.fullName && formik.touched.fullName
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="companyName"
          border="1px solid #EFEFF4"
          placeholder="Company Name"
          className={
            formik.errors.companyName && formik.touched.companyName
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="phoneNumber"
          border="1px solid #EFEFF4"
          placeholder="+92 3051234567"
          className={
            formik.errors.phoneNumber && formik.touched.phoneNumber
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="emailAddress"
          border="1px solid #EFEFF4"
          placeholder="Email Address"
          className={
            formik.errors.emailAddress && formik.touched.emailAddress
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={12} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="companyMailingAddress"
          border="1px solid #EFEFF4"
          placeholder="Company Mailing Adddress"
          className={
            formik.errors.companyMailingAddress &&
            formik.touched.companyMailingAddress
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      {/* <Col md={12}>
        <Row>
          <Col className="ps-4" md={6}>
            Country
          </Col>
          <Col className="ps-4" md={6}>
            State
          </Col>
        </Row>
      </Col> */}

      <Col md={6} className={"mb-3"}>
        <FormControl
          label="Country"
          control="select"
          type="text"
          name="country"
          border="1px solid #EFEFF4"
          placeholder="From 1"
          className={
            formik.errors.country && formik.touched.country
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      <Col md={6} className={"mb-3"}>
        <FormControl
          label="State"
          control="select"
          type="text"
          name="state"
          border="1px solid #EFEFF4"
          placeholder="From 1"
          className={
            formik.errors.state && formik.touched.state
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="city"
          border="1px solid #EFEFF4"
          placeholder="City"
          className={
            formik.errors.city && formik.touched.city
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="zipCode"
          border="1px solid #EFEFF4"
          placeholder="Zip Code"
          className={
            formik.errors.zipCode && formik.touched.zipCode
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="registrationNumber"
          border="1px solid #EFEFF4"
          placeholder="Registration Number"
          className={
            formik.errors.registrationNumber &&
            formik.touched.registrationNumber
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="website"
          border="1px solid #EFEFF4"
          placeholder="Website"
          className={
            formik.errors.website && formik.touched.website
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      {/* <Col md={12}>
        <Row>
          <Col className="ps-4" md={6}>
            Industry
          </Col>
          <Col className="ps-4" md={6}>
            Company Share
          </Col>
        </Row>
      </Col> */}

      <Col md={6} className={"mb-3"}>
        <FormControl
          label="Industry*"
          control="input"
          type="text"
          name="industry"
          border="1px solid #EFEFF4"
          placeholder="FOOD"
          className={
            formik.errors.industry && formik.touched.industry
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      <Col md={6} className={"mb-3"}>
        <FormControl
          label="Company Share"
          control="input"
          type="text"
          name="industryPhoto"
          border="1px solid #EFEFF4"
          placeholder="20 %"
          className={
            formik.errors.industryPhoto && formik.touched.industryPhoto
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      <Col md={6} className={"mb-3"}>
        <FormControl
          control="input"
          type="text"
          name="companyShare"
          border="1px solid #EFEFF4"
          placeholder="From 1"
          className={
            formik.errors.companyShare && formik.touched.companyShare
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>

      <Col md={6} className={"mb-3"}>
        <FormControl
          control="date"
          type="text"
          name="InsuranceExpirtyDate"
          border="1px solid #EFEFF4"
          placeholder="Insurance Expiry Date"
          className={
            formik.errors.InsuranceExpirtyDate &&
            formik.touched.InsuranceExpirtyDate
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
    </Row>
  );
};

export default Form1;
