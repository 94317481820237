import { Table } from "antd";
import { AntTableStyled } from "./styles";

const AntTable = (props) => {
  const { pagination, columns, dataSource, className } = props;
  return (
    <AntTableStyled>
      <Table
        pagination={pagination}
        columns={columns}
        scroll={{
          x: "auto",
          y: "75vh",
        }}
        dataSource={dataSource}
        className={className}
        rowClassName={(record) =>
          record.status === "InActive" ? "disabled-row" : ""
        }
      />
    </AntTableStyled>
  );
};

export default AntTable;
