import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const Editors = () => <div className='mt-3'>
    <Editor />
</div>


export default Editors