import styled from "styled-components";
import { secondaryDark3, whiteColor } from "../GlobalStyle";

export const NavbarStyled = styled.div`
  background: ${whiteColor};

  .custom_nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: ${whiteColor};
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  img {
    cursor: pointer;
  }
  .right-side {
    .name {
      margin-bottom: 0;
      color: ${secondaryDark3};
      font-size: 14px;
    }
    img {
      margin-left: 8px;
    }
  }
`;
