import styled from "styled-components";
import { lightGrey6 } from "./../../Components/GlobalStyle";

export const AdminListStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      padding: 8px 10px;
    }
  }

  .ant-table-tbody {
    .disabled-row {
      background-color: ${lightGrey6};
      pointer-events: none;
      opacity: 0.1;
    }
    .ant-table-cell {
      padding: 3px 10px;
    }
  }
`;

export const ViewInfoStyled = styled.div`
  background: #fff;
  margin: 2rem;
  padding: 2rem 1rem;

  .row_container {
    margin: 0 1.5rem 2rem 0;
    border-bottom: 1px solid black;

    .row_label {
      label {
        font: normal normal bold 19px EnnVisions;
        color: #1b283f;
        margin-bottom: 1rem;
      }
    }

    .row_value {
      p {
        font: normal normal 500 16px EnnVisions;
        color: #1b283f;
      }
    }
  }
  .action_icons {
    background: #e1e2e2;
    width: 32px;
    height: 32px;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
  }
  .deleteicon {
    background: #d63229 !important;
    cursor: pointer;
  }
  .editicon {
    background: #007aff !important;
    cursor: pointer;
  }
`;
