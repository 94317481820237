import styled from "styled-components";
import {
  lightColor1,
  PrimaryColor,
  tertiaryGrey13,
} from "../../Components/GlobalStyle";
import { secondaryDark3 } from "./../../Components/GlobalStyle";

export const StepperFormStyled = styled.div`
  background: #fff;
  margin: 2rem;
  padding: 2rem 1rem;

  .ant-steps {
    justify-content: space-between;
  }
  .ant-steps-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${tertiaryGrey13};
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${tertiaryGrey13};
  }
  .ant-steps-item-icon {
    border-radius: 2px;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-radius: 2px;
    width: 22px;
    height: 22px;
    background-color: ${PrimaryColor};
    border-color: ${PrimaryColor};
    line-height: 0;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }

  .ant-steps-item {
    flex: unset;
  }

  .ant-picker {
    width: 100%;
  }
  label {
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

export const ModalBox = styled.div`
  width: 421px;
  height: 230px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000017;
  border-radius: 7px;
  padding: 10px;
  .text {
    width: 336px;
    margin: 0 auto;
    text-align: center;
    font: normal normal normal 16px/22px EnnVisions;
    letter-spacing: 0px;
    color: #1b283f;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
