import { Container, Row, Col } from "react-bootstrap";
import { ViewInfoStyled } from "./styles";
import { Space } from "antd";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { adminData } from "./TableData";
import { useEffect } from "react";
import deleteIcon from "../../assets/icons/ic_delete.svg";
import editIcon from "../../assets/icons/ic_edit.svg";
import Confirmation from "../Confirmation/Index";
import CustomModal from "../../Components/Modal/CustomModal";

const ViewInfo = (props) => {
  const [user, setUser] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const params = useParams();

  const getUser = (id) => {
    const data = adminData.filter((d) => d.id === Number(id));
    setUser(data[0]);
  };

  const createField = (label, value) => {
    return (
      <Col md={6}>
        <div className="row_container">
          <div className="row_label">
            <label htmlFor="">{label}</label>
          </div>

          <div className="row_value">
            {!value ? (
              <p>N/A</p>
            ) : value && typeof value == "object" ? (
              <p>{value.name}</p>
            ) : (
              <p>{value}</p>
            )}
          </div>
        </div>
      </Col>
    );
  };

  useEffect(() => {
    getUser(params.id);
  }, [user]);

  return (
    <>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
      >
        <Confirmation setIsModalVisible={setIsDeleteModal} />
      </CustomModal>
      <Container>
        <ViewInfoStyled>
          <Row>
            <Col md={{ span: 3, offset: 8 }} className="text-end">
              <Space size="large">
                <div style={{ display: "flex", gap: "6px" }}></div>
                <div style={{ display: "flex", gap: "4px" }}>
                  <img
                    onClick={() => setIsDeleteModal(true)}
                    src={deleteIcon}
                    alt="delete Icon"
                    className="action_icons deleteicon"
                  />
                  <Link to={`/admin-edit/${user && user.id}`}>
                    <img
                      src={editIcon}
                      alt="edit Icon"
                      className="action_icons editicon"
                    />
                  </Link>
                </div>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <Row>
                {user && (
                  <>
                    {createField("Full Name", user.fullName)}
                    {createField("Company Name", user.title)}
                    {createField("Phone", user.phone)}
                    {createField("Email", user.email)}
                    {createField("Location", user.location)}
                    {createField("Address", user.address)}
                    {createField(
                      "Registration Number",
                      user.registrationNumber
                    )}
                    {createField("Website", user.website)}
                    {createField("License (Photo)", user.license)}
                    {createField("License Expiry Date", user.licenseExpiryDate)}
                    {createField("Insurance", user.insurance)}
                    {createField(
                      "Insurance Expiry Date",
                      user.insuranceExpiryDate
                    )}
                    {createField("Industry*", user.industry)}
                    {createField("Company Share*", user.companyShare)}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </ViewInfoStyled>
      </Container>
    </>
  );
};

export default ViewInfo;
