import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CreatePermissionStyled } from "../CreatePermissions/styles";
import { CustomSelect } from "../../Components/CustomSelect/Index";
import FormControl from "../../Components/FormControl";
const ViewPermission = () => {
  const params = useParams();
  useEffect(() => {}, []);
  return (
    <Container>
      <CreatePermissionStyled>
        <Row>
          <Col md={{ span: 5, offset: 0 }}>
            <div className="view_row">
              <div className="label">
                <label htmlFor="">Title</label>
              </div>

              <div className="value">
                <p>Admin</p>
              </div>
            </div>
            <div className="view_row">
              <div className="label">
                <label htmlFor="">Description</label>
              </div>

              <div className="value">
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est
                  doloribus ipsam eveniet tempore quidem incidunt magni voluptas
                  explicabo vitae dolorum beatae adipisci, vel aliquid ea
                  tenetur accusamus a perspiciatis sed!
                </p>
              </div>
            </div>
            <div className="view_row">
              <div className="label">
                <label htmlFor="">Status</label>
              </div>

              <div className="value">
                <p>Active</p>
              </div>
            </div>
          </Col>
        </Row>
      </CreatePermissionStyled>
    </Container>
  );
};

export default ViewPermission;
