import Inputs from "./Inputs";
import ProfileBox from "./EditProfessional";

const Index = () => {
  return (
    <>
    <ProfileBox/>
   <Inputs/>
    </>
  )
}

export default Index