import styled from "styled-components";
import { PrimaryColor } from "../../Components/GlobalStyle";

export const CustomCheckBoxContainer = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-color: ${PrimaryColor};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: gray;
    border-color: gray;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: ${PrimaryColor};
    border-color: ${PrimaryColor};
  }
`;
