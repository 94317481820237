import styled from "styled-components";
import {
  lightGrey6,
  lightGrey7,
  secondaryDark3,
} from "../../Components/GlobalStyle";

export const ProfessionalList = styled.div`
  margin-top: 28px;

  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
  }

  .deleteicon {
    background: #d63229 !important;
    cursor: pointer;
  }
  .editicon {
    background: #007aff !important;
    cursor: pointer;
  }
`;

export const MobileTableStyled = styled.div`
  .plus-icon {
    right: 5%;
    bottom: 5%;
    position: fixed;
    z-index: 30;
  }
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 16px;
  }

  .inner-section {
    padding-bottom: 10px;
    .card:first-child {
      margin-top: 12px;
    }
    .card:not(:first-child) {
      margin-top: 20px;
    }
    .card {
      padding: 12px;

      .name-section {
        .name {
          font-weight: 600;
        }
        .id {
          color: #156985;
        }
      }

      .details {
        margin-top: 10px;
        font-size: 16px;
      }
      .details-1 {
        margin-top: 10px;
        font-size: 13px;
      }

      .price-section {
        margin-top: 14px;
      }

      .actions-section {
        margin-top: 16px;
        img {
          background-color: #e1e2e2;
          padding: 10px;
          height: 35px;
        }
        img:not(:first-child) {
          margin-left: 4px;
        }

        .warn-actions {
          img:first-child {
            background-color: #c7112b;
          }
          img:last-child {
            background-color: #007aff;
          }
        }
      }
    }
  }
`;
