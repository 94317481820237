import { Container, Row, Col } from "react-bootstrap";
import { StepperFormStyled } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, validateYupSchema } from "formik";
import { PrimaryColor } from "../../Components/GlobalStyle";
import CustomButton from "../../Components/CustomButton/Index";
import { useEffect, useState } from "react";
import { Button, message, Steps, Icon, Form } from "antd";

import FormControl from "../../Components/FormControl";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import CustomModal from "../../Components/Modal/CustomModal";
// import "./styles";
import SuccessModal from "./SuccessModal";
import { stringTypeValidationMap } from "../../Helpers/validations";

const steps = [
  {
    title: "Basic Info",
    content: "First-content",
  },
  {
    title: "Theme Info",
    content: "Second-content",
  },
  {
    title: "Accounts Info",
    content: "Last-content",
  },
];

const Index = ({ initialValues, onSubmit, errorMessages, adminToEditId }) => {
  const [schemaValidationObject, setSchemaValidationObject] = useState({});
  const [successModal, setSuccessModal] = useState(false);

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const params = useParams();
  useEffect(() => {
    if (adminToEditId) {
      console.log(adminToEditId);
      console.log(initialValues);
    }
    const valid = stringTypeValidationMap(initialValues, errorMessages);
    console.log(valid);
    setSchemaValidationObject(valid);
  }, []);

  return (
    <>
      <Container>
        <StepperFormStyled>
          <Formik
            initialValues={initialValues}
            validationSchema={schemaValidationObject}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <>
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    autoComplete="off"
                  >
                    <Row>
                      <Col md={{ span: 8, offset: 2 }} className="mb-4">
                        <Steps current={current}>
                          {steps.map((item) => (
                            <Steps.Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={{ span: 10, offset: 1 }}>
                        <div className="steps-content">
                          {current === 0 && <Form1 formik={formik} />}
                          {current === 1 && <Form2 formik={formik} />}
                          {current === 2 && <Form3 formik={formik} />}
                        </div>
                        <div className="row flex-row-reverse ">
                          {current < steps.length - 1 && (
                            <Col md={6} className={"mt-4"}>
                              <CustomButton
                                bgcolor={`${PrimaryColor}`}
                                color="white"
                                padding="11px 8px"
                                width="100%"
                                // type="submit"
                                title="Next"
                                fontSize="16px"
                                clicked={next}
                              />
                            </Col>
                          )}
                          {current === steps.length - 1 && (
                            <Col md={6} className={"mt-4"}>
                              <CustomButton
                                bgcolor={`${PrimaryColor}`}
                                color="white"
                                padding="11px 8px"
                                width="100%"
                                // type="submit"
                                title="SUBMIT"
                                fontSize="16px"
                                clicked={() => setSuccessModal(true)}
                              />
                            </Col>
                          )}
                          {current > 0 && (
                            <Col md={6} className={"mt-4"}>
                              <CustomButton
                                bgcolor={`${PrimaryColor}`}
                                color="white"
                                padding="11px 8px"
                                width="100%"
                                // type="submit"
                                title="Previous"
                                fontSize="16px"
                                clicked={prev}
                              />
                            </Col>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className=""></Row>
                  </Form>
                </>
              );
            }}
          </Formik>
          <CustomModal
            isModalVisible={successModal}
            setIsModalVisible={setSuccessModal}
          >
            <SuccessModal />
          </CustomModal>
        </StepperFormStyled>
      </Container>
    </>
  );
};
export default Index;
