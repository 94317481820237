import { Container, Row, Col } from "react-bootstrap";
import { CreateRolesStyled } from "./styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, InputNumber } from "antd";
import FormControl from "../../Components/FormControl";

import CustomButton from "../../Components/CustomButton/Index";
import { useNavigate } from "react-router-dom";
import RolesRow from "./RolesRow";
// import "./styles";

let initialValues = {
  roleName: "",
  displayName: "",
  commission: "",
  fixCommission: "",
  roleDescription: "",
  user: false,
};

const validationSchema = Yup.object({
  roleName: Yup.string().required("Role Name is required!"),
  displayName: Yup.string().required("Display Name is required"),
  commission: Yup.string().required("Commission is required"),
  fixCommission: Yup.string().required("Fix Commission is required"),
  roleDescription: Yup.string().required("Role Description is required"),
  user: Yup.bool().oneOf([true], "User required"),
});
const Index = () => {
  const navigate = useNavigate();
  const onSubmit = (value) => {};

  const roleListNavigator = () => {
    navigate("/role-list");
  };

  return (
    <Container className="mt-3">
      <CreateRolesStyled>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <Row>
                  <Col md={{ span: 2 }}>
                    {/* <img src="" alt="" /> */}
                    <div className="img_container"></div>
                  </Col>
                  <Col md={{ span: 10 }}>
                    <Row>
                      <Col md={{ span: 4 }}>
                        <FormControl
                          control="input"
                          type="text"
                          name="roleName"
                          border="1px solid #EFEFF4"
                          placeholder="Role Name"
                          className={
                            formik.errors.roleName && formik.touched.roleName
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                      <Col md={{ span: 4 }}>
                        <FormControl
                          control="input"
                          type="text"
                          name="displayName"
                          border="1px solid #EFEFF4"
                          placeholder="Display Name"
                          className={
                            formik.errors.displayName &&
                            formik.touched.displayName
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                      <Col md={{ span: 4 }}>
                        <Row className="commissionRow">
                          <Col md={6}>
                            <FormControl
                              control="input"
                              type="text"
                              name="commission"
                              border="1px solid #EFEFF4"
                              placeholder="% Commission"
                              className={
                                formik.errors.commission &&
                                formik.touched.commission
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <FormControl
                              control="input"
                              type="text"
                              name="fixCommission"
                              border="1px solid #EFEFF4"
                              placeholder="Fix Commission"
                              className={
                                formik.errors.fixCommission &&
                                formik.touched.fixCommission
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 12 }}>
                        <FormControl
                          control="textarea"
                          type="text"
                          name="roleDescription"
                          border="1px solid #EFEFF4"
                          placeholder="Role Description"
                          className={
                            formik.errors.roleDescription &&
                            formik.touched.roleDescription
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="roles">
                    <div className="mainHeading">
                      <h4>Roles Permissions</h4>
                    </div>
                    <RolesRow heading={"Admin"} formik={formik} />
                    <RolesRow heading={"Fleet"} formik={formik} />
                    <RolesRow heading={"Vendor"} formik={formik} />
                    <RolesRow heading={"Driver"} formik={formik} />
                    <RolesRow heading={"Captain"} formik={formik} />
                    <RolesRow heading={"Partner"} formik={formik} />
                    <RolesRow heading={"Outlet"} formik={formik} />
                    <RolesRow heading={"Order"} formik={formik} />
                    <RolesRow heading={"Event"} formik={formik} />
                    <RolesRow heading={"Vehicle"} formik={formik} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </CreateRolesStyled>
    </Container>
  );
};
export default Index;
