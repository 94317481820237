import React from "react";
import { Field, ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
import { Checkbox, Input } from "antd";
import { CustomCheckBoxContainer } from "./styles";
const Index = (props) => {
  const {
    label,
    prefix,
    defaultValue,
    maxLength,
    disabled,
    placeholder,
    className,
    border = null,
    name,
    checked,
    type,
    ...rest
  } = props;
  return (
    <CustomCheckBoxContainer>
      <Checkbox
        id={name}
        name={name}
        className={className}
        disabled={disabled}
        checked={checked}
        {...rest}
      >
        {label}
      </Checkbox>
      <ErrorMessage name={name} component={ErrorMsg} />
    </CustomCheckBoxContainer>
  );
};

export default Index;
