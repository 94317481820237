import styled from "styled-components";
import { whiteColor } from "../GlobalStyle";

export const SocialFooterStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1030;
  .hero-footer {
    background-color: ${whiteColor};
    box-shadow: 0px -2px 6px #00000029;
    .icon {
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
`;
