import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StepperForm from "../../Components/StepperForm/Index";
import { adminData } from "./TableData";
const EditInfo = () => {
  const params = useParams();
  const [initialValues, setInitialValues] = useState({});
  const onSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    if (params.id) {
      const data = adminData.filter((user) => user.id === Number(params.id));

      setInitialValues(data[0]);
    }
  }, []);
  return (
    <>
      <StepperForm
        adminToEditId={params.id}
        errorMessages={""}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </>
  );
};

export default EditInfo;
