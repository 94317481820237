import * as Yup from "yup";

export const stringTypeValidationMap = (initialValuesObj, errorMsgsArr) => {
  let obj = {};
  Object.keys(initialValuesObj).forEach((field) => {
    obj[field] = Yup.string().required(errorMsgsArr[field]);
  });

  obj = Yup.object(obj);

  return obj;
};
