import avatar from "../../assets/icons/ic_user_role.svg";

export const adminData = [
  {
    id: 1,
    title: {
      img: avatar,
      name: "XP Eats",
    },
    username: "xpeats",
    phone: "+923008134076",
    commission: "20%",
    createdAt: "13-August-2020 12:34 AM",
    status: "Active",
    fullName: "Adnan",
  },
  {
    id: 2,
    title: {
      img: avatar,
      name: "XP Eats",
    },
    username: "xpeats",
    phone: "+923008134076",
    commission: "20%",
    createdAt: "13-August-2020 12:34 AM",
    status: "InActive",
  },
  {
    id: 3,
    title: {
      img: avatar,
      name: "Jimmy Guaco's",
    },
    username: "jimmyguaco's",
    phone: "+923008134076",
    commission: "20%",
    createdAt: "13-August-2020 12:34 AM",
    status: "Active",
  },
];
