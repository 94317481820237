import { CustomSwitchStyled } from "./styles";
import { Switch } from "antd";
const Index = (props) => {
  const {
    label,
    prefix,
    defaultValue,
    maxLength,
    disabled,
    placeholder,
    className,
    border = null,
    name,
    checked,
    type,
    ...rest
  } = props;
  return (
    <CustomSwitchStyled>
      <Switch
        name={name}
        id={name}
        className={className}
        disabled={disabled}
        {...rest}
      />
    </CustomSwitchStyled>
  );
};
export default Index;
