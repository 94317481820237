import avatar from "../../assets/icons/ic_user_role.svg";

export const permissionsData = [
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",

    createdAt: "13-August-2020 12:34 AM",
  },
];
