import avatar from "../../assets/icons/ic_user_role.svg";

export const rolesData = [
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
  {
    title: {
      img: avatar,
      name: "Adnan Qureshi",
    },
    roleDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    commission: "1% - $2.99",
    createdAt: "13-August-2020 12:34 AM",
  },
];
