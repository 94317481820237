import { useContext, useState, useEffect } from "react";
import expandIcon from "../../assets/icons/ic_blog_detail_arrow.svg";
import { SidebarContext } from "../../App";
import { SidebarStyled } from "./Style";
import { useNavigate, useParams } from "react-router-dom";
import CustomDrawer from "../Drawer/Index";
const Sidebar = (props) => {
  const navigate = useNavigate();

  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const [sidebarData, setSidebarData] = useState([
    {
      create_link: "create-permissions",
      expand: false,
      list_link: "permissions-list",
      name: "Permissions",
    },
    {
      create_link: "create-roles",
      expand: false,
      list_link: "role-list",
      name: "Role",
    },
    {
      create_link: "create-admin",
      expand: false,
      list_link: "admin-list",
      name: "Admin",
    },
    {
      create_link: "create-user",
      expand: false,
      list_link: "user-list",
      name: "User",
    },
    {
      create_link: "create-professional",
      expand: false,
      list_link: "professional-list",
      name: "Propessional",
    },
    {
      create_link: "list-property-1",
      expand: false,
      list_link: "property-list",
      name: "Property",
    },
    {
      create_link: "post-list",
      expand: false,
      list_link: "post-list",
      name: "Post",
    },
    { create_link: "", expand: false, list_link: "story-list", name: "Story" },
    {
      create_link: "",
      expand: false,
      list_link: "newsfeed-list",
      name: "Newfeed",
    },
    { create_link: "", expand: false, list_link: "post-list", name: "Boost" },
    {
      create_link: "",
      expand: false,
      list_link: "request-list",
      name: "Request",
    },
    { create_link: "", expand: false, list_link: "user-list", name: "User" },
    {
      create_link: "",
      expand: false,
      list_link: "professional-list",
      name: "Professional",
    },
    {
      create_link: "",
      expand: false,
      list_link: "property-list",
      name: "Property",
    },
    { create_link: "", expand: false, list_link: "post-list", name: "Post" },
    { create_link: "", expand: false, list_link: "story-list", name: "Story" },
    {
      create_link: "",
      expand: false,
      list_link: "newsfeed-list",
      name: "Newfeed",
    },
    { create_link: "", expand: false, list_link: "post-list", name: "Boost" },
    {
      create_link: "",
      expand: false,
      list_link: "request-list",
      name: "Request",
    },
  ]);

  const expandMenu = (index, name) => {
    sidebarData.map((menu) => {
      menu.expand = false;
    });
    sidebarData[index].expand = !sidebarData[index].expand;
    setSidebarData([...sidebarData]);
  };

  const naivgateDashboard = () => {
    navigate("/");
    setShowSidebar(!showSidebar);
  };
  const createNavigation = (link) => {
    navigate(link);
    setShowSidebar(!showSidebar);
  };
  const listNavigation = (link) => {
    navigate(link);
    setShowSidebar(!showSidebar);
  };

  let pathname = window.location.pathname;
  return (
    <CustomDrawer>
      <SidebarStyled className={`${showSidebar ? "d-block" : "d-none"}`}>
        <p
          onClick={naivgateDashboard}
          className={`${pathname === "/" && "active"}`}
        >
          Dashboard
        </p>
        {sidebarData.map(({ name, list_link, create_link, expand }, index) => (
          <div key={index} className={`inner-container`}>
            <p
              className={`mt-2 ${
                (pathname === `/${list_link}` ||
                  pathname === `/${create_link}`) &&
                "active"
              }`}
              onClick={() => expandMenu(index, name)}
            >
              {name}
            </p>
            {
              <div className={`expand-menu ${expand ? "d-block" : "d-none"}`}>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p
                    onClick={() => createNavigation(create_link)}
                    className={pathname === `/${create_link}` && "active"}
                  >
                    -Create
                  </p>
                  <img src={expandIcon} alt="create-link" />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p
                    onClick={() => listNavigation(list_link)}
                    className={pathname === `/${list_link}` && "active"}
                  >
                    -List
                  </p>
                  <img src={expandIcon} alt="create_link:'',expand" />
                </div>
              </div>
            }
          </div>
        ))}
      </SidebarStyled>
    </CustomDrawer>
  );
};

export default Sidebar;
