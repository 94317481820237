import styled from "styled-components";

export const ColorInputStyled = styled.div`
  padding: 9px 0;
  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 auto;
    padding: 2px 8px 0;
  }
  .ant-input {
    border: none;
    padding: 0 !important;
  }
  label {
    margin-bottom: 0;
    margin-left: 0;
  }
`;
