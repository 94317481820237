import { ColorInputStyled } from "./styles";
import FormControl from "../FormControl";
import { ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
const Index = ({ name, formik }) => {
  return (
    <ColorInputStyled>
      <div className="border">
        <FormControl
          colorPicker={true}
          noErrorMsg={true}
          control={"input"}
          type="color"
          name={name}
          className={`colorPicker ${
            formik.errors.form2 && formik.touched.form2
              ? "is-invalid"
              : "customInput"
          }`}
        />

        <FormControl
          control="input"
          colorInput={true}
          noErrorMsg={true}
          type="text"
          name={name}
          placeholder="From 2"
          className={`colorInput ${
            formik.errors.form2 && formik.touched.form2
              ? "is-invalid"
              : "customInput"
          }`}
        />
      </div>
      <ErrorMessage name={name} component={ErrorMsg} />
    </ColorInputStyled>
  );
};

export default Index;
