import styled from "styled-components";
import { PrimaryColor } from "../GlobalStyle";

export const CustomSwitchStyled = styled.div`
  .ant-switch-checked {
    background: ${PrimaryColor};
  }
  .ant-switch {
    min-width: 35px;
    height: 19px;
  }
  .ant-switch-handle {
    width: 14px;
    height: 14px;
  }
`;
