import { Container, Row, Col } from "react-bootstrap";
import { CreateAdminStyled } from "./styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, validateYupSchema } from "formik";
import { PrimaryColor } from "../../Components/GlobalStyle";
import CustomButton from "../../Components/CustomButton/Index";
import { useEffect, useState } from "react";
import { Button, message, Steps, Icon, Form } from "antd";

// import "./styles";
import { stringTypeValidationMap } from "../../Helpers/validations";
import StepperForm from "../../Components/StepperForm/Index";

const initialValues = {
  fullName: "",
  companyName: "",
  phoneNumber: "",
  emailAddress: "",
  companyMailingAddress: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  registrationNumber: "",
  website: "",
  industry: "",
  industryPhoto: "",
  companyShare: "",
  InsuranceExpirtyDate: "",

  appNameCustomer: "",
  primaryColorCustomer: "",
  secondaryColorCustomer: "",
  appLogoCustomer: "",

  appNameDriver: "",
  primaryColorDriver: "",
  secondaryColorDriver: "",
  appLogoDriver: "",

  appNameWaiter: "",
  primaryColorWaiter: "",
  secondaryColorWaiter: "",
  appLogoWaiter: "",

  appNameManager: "",
  primaryColorManager: "",
  secondaryColorManager: "",
  appLogoManager: "",

  appNameMerchant: "",
  primaryColorMerchant: "",
  secondaryColorMerchant: "",
  appLogoMerchant: "",

  stripSecretKey: "",
  stripPublicKey: "",
  twilioSidKey: "",
  twilioAuthToken: "",
  googlePlaceKey: "",
  googleSdk: "",
};

const errorMessages = {
  fullName: "Full Name is required!",
  companyName: "Company Name is required!",
  phoneNumber: "Phone Number is required!",
  emailAddress: "Email Address is required!",
  companyMailingAddress: "Company Mailing Address is required!",
  country: "Country is required!",
  state: "State is required!",
  city: "City is required!",
  zipCode: "Zip Code is required!",
  registrationNumber: "Registration Number is required!",
  website: "Website is required!",
  industry: "Industry is required!",
  industryPhoto: "IndustryPhoto is required!",
  companyShare: "Company Share is required!",
  InsuranceExpirtyDate: "Insurance Expirty Date is required!",

  appNameCustomer: "App Name Customer is required!",
  primaryColorCustomer: "Primary Color Customer is required!",
  secondaryColorCustomer: "Secondary Color Customer is required!",
  appLogoCustomer: "App Logo Customer is required!",

  appNameDriver: "App Name Driver is required!",
  primaryColorDriver: "Primary Color Driver is required!",
  secondaryColorDriver: "Secondary Color Driver is required!",
  appLogoDriver: "App Logo Driver is required!",

  appNameWaiter: "App Name Waiter is required!",
  primaryColorWaiter: "Primary Color Waiter is required!",
  secondaryColorWaiter: "Secondary Color Waiter is required!",
  appLogoWaiter: "App Logo Waiter is required!",

  appNameManager: "App Name Manager is required!",
  primaryColorManager: "Primary Color Manager is required!",
  secondaryColorManager: "Secondary Color Manager is required!",
  appLogoManager: "App Logo Manager is required!",

  appNameMerchant: "App Name Merchant is required!",
  primaryColorMerchant: "Primary Color Merchant is required!",
  secondaryColorMerchant: "Secondary Color Merchant is required!",
  appLogoMerchant: "App Logo Merchant is required!",

  stripSecretKey: "Strip Secret Key is required!",
  stripPublicKey: "Strip Public Key is required!",
  twilioSidKey: "Twilio Sid Key is required!",
  twilioAuthToken: "Twilio Auth Token is required!",
  googlePlaceKey: "Google Place Key is required!",
  googleSdk: "Google Sdk is required!",
};
const steps = [
  {
    title: "Basic Info",
    content: "First-content",
  },
  {
    title: "Theme Info",
    content: "Second-content",
  },
  {
    title: "Accounts Info",
    content: "Last-content",
  },
];

const Index = () => {
  const [schemaValidationObject, setSchemaValidationObject] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (value) => {
    console.log("value", value);
  };

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const valid = stringTypeValidationMap(initialValues, errorMessages);

    setSchemaValidationObject(valid);
  }, []);

  return (
    <>
      <StepperForm
        initialValues={initialValues}
        onSubmit={onsubmit}
        errorMessages={errorMessages}
      ></StepperForm>
    </>
  );
};
export default Index;
