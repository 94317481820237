import { Col, Row } from "react-bootstrap";
import FormControl from "../../Components/FormControl";

const Form3 = ({ formik }) => {
  return (
    <Row>
      <Col md={6}>
        <FormControl
          label="Strip Secret Key"
          control="input"
          type="text"
          name="stripSecretKey"
          border="1px solid #EFEFF4"
          placeholder="Enter Key here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6}>
        <FormControl
          label="Strip Public Key"
          control="input"
          type="text"
          name="stripPublicKey"
          border="1px solid #EFEFF4"
          placeholder="Enter Key here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6}>
        <FormControl
          label="Twilio SID Key"
          control="input"
          type="text"
          name="twilioSidKey"
          border="1px solid #EFEFF4"
          placeholder="Enter Key here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6}>
        <FormControl
          label="Twilio Auth Token"
          control="input"
          type="text"
          name="twilioAuthToken"
          border="1px solid #EFEFF4"
          placeholder="Enter Key here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6}>
        <FormControl
          label="Google Place Key"
          control="input"
          type="text"
          name="googlePlaceKey"
          border="1px solid #EFEFF4"
          placeholder="Enter Key here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
      <Col md={6}>
        <FormControl
          label="Google"
          control="input"
          type="text"
          name="googleSdk"
          border="1px solid #EFEFF4"
          placeholder="Enter sdk here"
          className={
            formik.errors.form3 && formik.touched.form3
              ? "is-invalid"
              : "customInput"
          }
        />
      </Col>
    </Row>
  );
};

export default Form3;
