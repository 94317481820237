import FormControl from "../../Components/FormControl";

const RolesRow = ({ heading, formik }) => {
  return (
    <div className="rolesrow">
      <div className="heading">
        <h5>{heading}</h5>
      </div>
      <div className="permissions_row">
        {["Create New", "View List", "Update", "Edit", "Analysis"].map(
          (label, index) => {
            return (
              <div className="permission_col" key={index}>
                <FormControl
                  control="checkbox"
                  name="user"
                  border="1px solid #EFEFF4"
                  label={label}
                />
                <FormControl
                  control="switch"
                  // type="checkbox"
                  name="user"
                  border="1px solid #EFEFF4"
                  // label={"Create user"}
                />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default RolesRow;
