import avatar from '../../assets/icons/ic_user_role.svg'
  
 export const adminsData = [
    {
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },
    {
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },{
      fullName: {
        img: avatar, name: 'Adnan Qureshi',
      },
      email: 'adnan.ahsan21@gmail.com',
      phone: '+923008134076',
      createdAt: '13-August-2020 12:34 AM'
    },
  ]