import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, InputNumber } from "antd";
import FormControl from "../../Components/FormControl";

import CustomButton from "../../Components/CustomButton/Index";
import { useNavigate } from "react-router-dom";
import SocialFooter from "../../Components/SocialFooter/SocialFooter";
import { Helmet } from "react-helmet";
import flag from "../../assets/icons/ic_flag.svg";
import { tertiaryGrey11, PrimaryColor } from "../../Components/GlobalStyle";
import { CreatePermissionStyled } from "./styles";

let initialValues = {
  title: "",
  details: "",
  status: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required!"),
  details: Yup.string().required("details is required"),
  status: Yup.string().required("Status is required"),
});
const Index = () => {
  const navigate = useNavigate();
  const onSubmit = (value) => {};
  const permissionsListNavigator = () => {
    navigate("/permissions-list");
  };
  return (
    <Container>
      <CreatePermissionStyled>
        {/* Create Permissions page */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Row>
                {console.log(formik)}

                <Col md={{ span: 5, offset: 0 }}>
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    validateMessages={validationSchema}
                  >
                    <FormControl
                      control="input"
                      type="text"
                      name="title"
                      border="1px solid #EFEFF4"
                      placeholder="Title"
                      className={
                        formik.errors.title && formik.touched.title
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                    <FormControl
                      control="textarea"
                      type="textarea"
                      name="details"
                      border="1px solid #EFEFF4"
                      placeholder="Details"
                      className={
                        formik.errors.details && formik.touched.details
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                    <div className="mt-1">
                      <>Stats</>
                    </div>
                    <FormControl
                      control="select"
                      type="text"
                      name="status"
                      border="1px solid #EFEFF4"
                      placeholder="Stats"
                      options={[
                        { id: 1, name: "Active" },
                        { id: 2, name: "InActive" },
                      ]}
                      className={
                        formik.errors.status && formik.touched.status
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                    <div className="mt-1">
                      <CustomButton
                        bgcolor={`${PrimaryColor}`}
                        color="white"
                        padding="11px 8px"
                        width="100%"
                        type="submit"
                        title="SUBMIT"
                        fontSize="16px"
                        clicked={permissionsListNavigator}
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
            );
          }}
        </Formik>
      </CreatePermissionStyled>
    </Container>
  );
};
export default Index;
