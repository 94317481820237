import styled from "styled-components";
import { secondaryDark3 } from "./../../Components/GlobalStyle";
export const CreateRolesStyled = styled.div`
  background: #fff;
  padding: 13px;

  .img_container {
    width: 170px;
    max-width: 100%;
    height: 180px;
    background: #f3f6f9;
    /* margin-left: 1rem; */
    margin: 0 auto;
  }
  .roles {
    margin-left: 1rem;
    .mainHeading {
      /* margin-left: 2rem; */
      h4 {
        font-size: 18px;
        color: ${secondaryDark3};
        font-weight: 600;
      }
    }
    .rolesrow {
      margin-bottom: 2rem;

      .heading {
        h5 {
          font-size: 16px;
          font-weight: 600;
          color: ${secondaryDark3};
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .commissionRow {
      .col-md-6 {
        padding: 0;
      }
      .col-md-6:nth-child(2) {
        padding-right: 13px;
      }
    }
  }

  .permissions_row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* justify-items: center; */
    gap: 1rem;
    .permission_col {
      display: flex;
    }

    @media screen and (max-width: 998px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 530px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 370px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: flex-start;
    }
  }
`;
