import { ModalBox } from "../../Screens/CreateAdmin/styles";
import CustomButton from "../CustomButton/Index";
import { PrimaryColor } from "../GlobalStyle";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();

  return (
    <ModalBox>
      <div className="img_container">
        <img src="" alt="" />
      </div>
      <p className="text">
        Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy
        Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
      </p>
      <CustomButton
        bgcolor={`${PrimaryColor}`}
        color="white"
        padding="11px 8px"
        width="100%"
        // type="submit"
        title="THANKS"
        fontSize="16px"
        clicked={() => navigate("/admin-list")}
      />
    </ModalBox>
  );
};

export default Index;
