import { Col, Row } from "react-bootstrap";
import FormControl from "../../Components/FormControl";
import ColorInput from "../../Components/ColorInput/Index";
import React from "react";

const Form2 = ({ formik }) => {
  const fields = [
    {
      Label: "Customer",
      names: {
        appName: "appNameCustomer",
        primaryColor: "primaryColorCustomer",
        secondaryColor: "secondaryColorCustomer",
        appLogo: "appLogoCustomer",
      },
    },
    {
      Label: "Driver",
      names: {
        appName: "appNameDriver",
        primaryColor: "primaryColorDriver",
        secondaryColor: "secondaryColorDriver",
        appLogo: "appLogoDriver",
      },
    },
    {
      Label: "Waiter",
      names: {
        appName: "appNameWaiter",
        primaryColor: "primaryColorWaiter",
        secondaryColor: "secondaryColorWaiter",
        appLogo: "appLogoWaiter",
      },
    },
    {
      Label: "Manager",
      names: {
        appName: "appNameManager",
        primaryColor: "primaryColorManager",
        secondaryColor: "secondaryColorManager",
        appLogo: "appLogoManager",
      },
    },
    {
      Label: "Merchant",
      names: {
        appName: "appNameMerchant",
        primaryColor: "primaryColorMerchant",
        secondaryColor: "secondaryColorMerchant",
        appLogo: "appLogoMerchant",
      },
    },
  ];
  return (
    <>
      {fields.map((row, index) => {
        return (
          <Row key={index} className={"mb-4"}>
            <Col md={12}>
              <h5>{row.Label}</h5>
            </Col>
            <Col md={3} className={"mb-3"}>
              <FormControl
                control="input"
                type="text"
                name={row.names.appName}
                border="1px solid #EFEFF4"
                placeholder="From 2"
                className={
                  formik.errors.form2 && formik.touched.form2
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
            <Col md={3} className={"mb-3"}>
              <ColorInput formik={formik} name={row.names.primaryColor} />
            </Col>
            <Col md={3} className={"mb-3"}>
              <ColorInput formik={formik} name={row.names.secondaryColor} />
            </Col>
            <Col md={3} className={"mb-3"}>
              <FormControl
                control="input"
                type="text"
                name={row.names.appLogo}
                border="1px solid #EFEFF4"
                placeholder="From 2"
                className={
                  formik.errors.form2 && formik.touched.form2
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default Form2;
