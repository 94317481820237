import styled from "styled-components";

export const CreatePermissionStyled = styled.div`
  background: #fff;
  padding: 7px 15px;
  margin: 1rem auto 2rem;
  width: 1202px;
  height: 526px;
  max-width: 100%;

  @media screen and (max-width: 1100px) {
    width: 900px;
  }

  @media screen and (max-width: 992px) {
    width: 700px;
  }

  @media screen and (max-width: 768px) {
    width: 500px;
  }

  @media screen and (max-width: 567px) {
    width: 100%;
  }

  .view_row {
    margin-top: 2rem;
    border-bottom: 1px solid black;
    margin-left: 2rem;
    label {
      margin-bottom: 0.6rem;
      font-weight: bold;
      font-size: 19px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
